<template>
  <div class="flex items-center gap-2 justify-between">
    <div class="flex items-center gap-2 w-full">
      <UButton
        v-if="allowGoBack"
        icon="i-heroicons-arrow-left"
        color="gray"
        size="xs"
        variant="ghost"
        data-test="back-button"
        @click="onNavigate(lastPage)"
      />
      <Tooltip v-if="!canEdit" :text="title">
        <template #default="{ getTextRef }">
          <h3 :id="hash" :ref="getTextRef" class="text-xl font-semibold line-clamp-1 break-all">
            {{ title }}
          </h3>
        </template>
      </Tooltip>
      <Tooltip v-else class="w-full" :text="inputValue">
        <template #default="{ getTextRef }">
          <InputResizeInput
            v-model:text="inputValue"
            data-test="input-board-setting-title"
            :max-length="255"
            :placeholder="placeholder"
            :disabled="!can('settings.settings_packs.manage_automations')"
            edit-mode
            class="min-w-96"
            text-class="text-xl ring-0 font-semibold outline-none"
            :get-text-ref="getTextRef"
            @change="emits('change', inputValue)"
          />
        </template>
      </Tooltip>
      <slot />
    </div>
    <UButton
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark-20-solid"
      size="xs"
      data-test="close-setting-btn"
      @click="closeSidebarSetting"
    />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  hash: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  allowGoBack: {
    type: Boolean,
    default: true,
  },
  lastPage: {
    type: String,
    default: 'index',
  },
  canEdit: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Enter title',
  },
})

const emits = defineEmits(['change'])

const route = useRoute()
const router = useRouter()
const { setSettingTab, closeSettings } = useBoardSettingsNavigator()
const { isPreventOpenTaskDetail } = useModalManager()
const { can } = useBoardAbility()
const closeSidebarSetting = () => {
  isPreventOpenTaskDetail.value = false
  nextTick(() => {
    closeSettings()
  })
}

const inputValue = ref(props.title)

const onNavigate = async (page: string) => {
  const { id, ...cleanQuery } = route.query
  if (page === 'index' && id) {
    await router.push({ query: cleanQuery })
  }

  setSettingTab(page)
}

watch(
  () => props.title,
  (value) => {
    inputValue.value = value
  })
</script>
